const recruitagf = [
  "课程讲师",
  "班主任",
  "课程开发主管",
  "行测申论高级讲师",
  // "直播运营",
  // "销售",
];

const recruitinfof = [
  {
    wordcontent: `【直播授课】抖音平台直播授课：目前以公务员事业编相关考试内容授课为主。<br />
        `,
    // 【学员管理】根据学员情况和需要，。<br />
    // 【服务维护】有效管理学员，督促回访学习情况。
    pay: `【薪酬】<strong style='color:red'>30-60K</strong> 无责高底薪 +课时费+奖金。<br />
        【工作时间】13点-22点，晚上19点到22点讲课，法定节假日放假。<br />
        【保险】正规缴纳五险（养老、失业、医疗、工伤、生育五险）。<br />
        【环境】办公优雅环境。`,
    require: `【工作经验】至少5年以上公考类目教学、培训经验。<br />
        【工作试岗】能接受公司内部试岗，考核期1个月。<br />
        【学历背景】硕士及以上学历，普通话标准。<br />
        【考核要求】拥有成熟的职场素养和优秀的职场习惯，公司会竞调以往教学成果。`,
  },
  {
    wordcontent: `【协助教学】协助同事进行日常综合管理，对教师教学质量进行跟踪、调查及反馈，并提出改进意见。<br />
        【课程整理】排课、调课；对学生进行日常管理、及时了解学生的学习情况，且辅助教学老师完成课件制作。<br />
        【学生管理】根据学生的学习目标和学习能力评估，对学员进行专业的学习建议和学习规划。<br />
        【定期回访】定期对学员进行回访，做好课后反馈及服务工作，有对我们服务不满意的学生，第一时间追责班主任。<br />
        【临时任务】完成一定的突发临时性工作，能够接受打电话回访学员。
        `,
    pay: `【薪酬】<strong style='color:red'>8-15K</strong> 无责高底薪+奖金。<br />
        【工作时间】13点-22点，排班，法定节假日放假。<br />
        【保险】正规缴纳五险（养老、失业、医疗、工伤、生育五险）。<br />
        【环境】办公优雅环境。<br/>
        【其他】本岗位为文职岗，不带销售性质！！！`,
    require: `【态度第一】只要你有态度，其他都能在这里学习。。<br />
        【学历背景】大专及以上学历；有课程顾问经验，或公考方面备考经历者优先。<br />`,
  },
  {
    wordcontent: `【课程研发】为博轩教研实验室做课程研发和优化。<br />
        【数据分析】根据学生上课的数据反馈对目前课程系统进行优化。<br />
        `,
    pay: `【薪酬】<strong style='color:red'>40-60K</strong> 无责高底薪+奖金。<br />
        【工作时间】13点-22点，法定节假日放假。<br />
        【保险】正规缴纳五险（养老、失业、医疗、工伤、生育五险）。<br />
        【环境】办公优雅环境。`,
    require: `【工作经验】至少5年以上公考类目课程研发经验，有公考教研组成员履历优先录取。 <br />
        【学历背景】硕士及以上学历，普通话标准。<br />
        【考核要求】能接受最少2个月一次的课程升级要求，公司会进行年度押题率考核。`,
  },
  {
    wordcontent: `【教学研究】根据公司战略规划，结合公考行业发展趋势及相应的教育行业市场需求，对教学及研究方向把关。<br />
        【产品打造】负责学科相关视频的项目研发及教学产品打造，引领博轩成为公考教育行业支柱型力量。<br />
        【形象推广】提升公司口碑与品牌美誉度，坚持“一切为了学员”“变态严管”“师资优质”的核心价值观。<br />
        【课程教研】负责公司行测方向课程架构设计，跟踪和分析市场、行业情况，研发含量高的课程。<br />
        【竞品分析】负责分析调研同行竞品机构，深入分析理解并不断挖掘公考考试需求，打造符合千人千面的教学课程。
        `,
    pay: `【薪酬待遇】薪酬和工作时间面议。<br />
        【保险】正规缴纳五险（养老、失业、医疗、工伤、生育五险）。<br />
        【环境】办公优雅环境。`,
    require: `
        【工作经验】具有5年以上的公考行业授课经验，必须自身考上过公务员（携带考试成绩），拥有国家、省市考试中心命题经验者优先；有担任各级公考面试考官经历者优先。<br />
        【学历背景】985/211重点本科以上学历，文学、哲学、经济学、管理学、理学、工学、心理学、历史等相关专业。<br />
        【个人素质】语言表达流畅，逻辑思维清晰，具备较强的学习能力和创新能力，追求教育理想，热爱教育事业，传递爱与奉献，积极上进，责任心强，有良好的抗压能力，乐于接受挑战。`,
  },
  {
    wordcontent: `【数据分析】后台数据分析，根据直播数据给主播发送指令调整直播。<br />
        【协助工作】负责对接、协调直播间产品及开播时间等事宜，负责直播间产品链接、活动推送及场控工作。<br />
        【流程总结】协助老师解决日常直播问题，下播及时给老师进行复盘，以及其他直播相关工作。`,
    pay: `【薪酬】5-10k，具体面议。<br />
        【工作时间】13点-22点，单双休，法定节假日放假。<br />
        【保险】正规缴纳五险（养老、失业、医疗、工伤、生育五险）。<br />
        【环境】办公优雅环境。`,
    require: `【态度第一】只要你有态度，其他都能在这里学习。<br />
        【头脑灵活】比较灵活，较强的应变能力。<br />
        【学历背景】大专及以上学历；有课程顾问经验，或公考方面备考经历者优先。<br />
        【团队合作】有良好的团队协作能力，乐于接受新鲜事物，高执行力强。`,
  },
  {
    wordcontent: `【用户引导】公司分配资源，将学员导入到公开课，简单介绍课程，躺单模式。<br />
        【数据处理】每日一手数据量50-100条，行业大佬营销课帮助出单。
        `,
    pay: `【薪酬】无责底薪4-6K+提成+奖金；资深员工薪资高于行业平均水平。<br />
        【晋升】上升路径明确，资深顾问-顾问组长-销售总监，三个月一晋升,奖金均大幅提高。<br />
        【保险】正规缴纳五险（养老、失业、医疗、工伤、生育五险）。<br />
        【环境】办公优雅环境`,
    require: `【态度第一】只要你有态度，其他都能在这里学习。<br />
        【销售热忱】强烈的语言表达欲，喜欢在线语音文字沟通，对学生的问题富有耐心和热情。<br />
        【学历背景】大专及以上学历；有课程顾问经验，或公考方面备考经历者优先。<br />
        【勤奋上进】有升迁愿望，希望实现经济独立和自由，勤奋踏实，自我驱动力强。`,
  },
];

const recruitags = ["平面设计", "人事专员", "行政专员", "销售主管"];

const recruitinfos = [
  {
    wordcontent: `【设计技能】能熟练使用PS、PR、DW、AI等软件，抠图修图，编辑排版。<br />
        【美术功底】具备一定的美术功底，公司内部宣传海报的排版、设计以及文案编辑。<br />
        【设计能力】负责部分网页设计，活动、宣传图等设计。`,
    pay: `【薪酬】6-10K，具体面议。<br />
        【工作时间】9点-18点，大小休，法定节假日放假。<br />
        【保险】缴纳五险（养老、失业、医疗、工伤、生育五险）。<br />
        【环境】办公优雅环境。`,
    require: `
        【专业技能】有编辑或美工工作一年以上经验，具备设计专业背景，能精确把握教育网站的页面配色，有教育行业网站经验优先。<br />
        【学历背景】大专及以上学历；美术类大专以上学历，良好的美术功底和优秀的创意实现能力，对色彩有深刻的把握力特色的设计风格。<br />
        【文案创作】擅长文案创作、编辑排版、图文并茂的强化文案表现力，有独到的创意视点与创新意识，丰富的设计理念知识。<br />
        【团队合作】良好的沟通能力及团队协作能力，富有责任心、思维活跃，富有创新。<br />
        【用户视角】能够不断创新并站在用户角度上对网站用户界面提出自己的整改方案和意见。
        `,
  },
  {
    wordcontent: `
        【业务处理】深入了解业务，制定业务部门合理有效的招聘计划，以满足各业务部门的用人需求。<br />
        【统筹工作】拓展相关招聘渠道及相应招聘策略端支持，促进公司招聘达成率完成；利用各种招聘渠道执行招聘、甄选、面试。<br />
        【数据完善】负责建立、完善和维护人才库。
        `,
    pay: `
        【薪酬】5-7K，具体面议。<br />
        【工作时间】10点-19点，大小休，法定节假日放假。<br />
        【保险】缴纳五险（养老、失业、医疗、工伤、生育五险）。<br />
        【环境】办公优雅环境。
        `,
    require: `
        【学历背景】大专及以上学历；有课程顾问经验，或公考方面备考经历者优先。<br />
        `,
  },
  {
    wordcontent: `
        【日常工作】办理员工入职手续，员工统计工作，负责办公用品管理与采购事宜。<br />
        【统筹工作】办理员工入离职手续，监督检查工作交接落实情况，协助落实企业文化建设工作。<br />
        【协助工作】协助进行员工日常管理，包括考勤统计、出入登记、日常监督等。
        `,
    pay: `
        【薪酬】5-6K，具体面议。<br />
        【工作时间】10点-19点，大小休，法定节假日放假。<br />
        【保险】缴纳五险（养老、失业、医疗、工伤、生育五险）。<br />
        【环境】办公优雅环境。
        `,
    require: `
        【学历背景】大专及以上学历；有课程顾问经验，或公考方面备考经历者优先。<br />
        `,
  },
  {
    wordcontent: `
        【储备岗位】岗位是储备管理者招聘，需要先做一线了解业务知识，优秀者可以升主管。<br />
        【指标达成】销售业绩达成：高质量完成个人业绩并带领团队完成公司下达的销售指标。<br />
        【协助工作】辅助经理及主管做管理工作，做好上级下属的沟通桥梁以及主导作用，活跃组内气氛。<br />
        【监听辅导】参与销售部日常工作，及时解决组员在通话过程中所遇到的问题，及时给出解决方案，迭代话术和提升组员的业务能力。
        `,
    pay: `
        【薪酬】底薪5-8K，具体面议。<br />
        【工作时间】工作时间面议，法定节假日放假。<br />
        【保险】缴纳五险（养老、失业、医疗、工伤、生育五险）。<br />
        【环境】办公优雅环境。
        `,
    require: `
        【学历背景】大专及以上学历；有课程顾问经验，或公考方面备考经历者优先。<br />
        【工作经验】从事过电话销售工作2年以上或者做过电话管理工作1年以上，有教育行业工作经验优先。<br />
        【销售热忱】热爱销售，有较强的沟通能力、服务意识、团队合作精神、抗压能力、责任心与执行力。
        `,
  },
];

const jobinfo = [
 

  {
    work: [
      {
        tag: "【教学研究】",
        tagdes:
          "根据公司战略规划，结合公考行业发展趋势及相应的教育行业市场需求，对教学及研究方向把关。",
      },
      {
        tag: "【产品打造】",
        tagdes:
          "负责学科相关视频的项目研发及教学产品打造，引领博轩成为公考教育行业支柱型力量。",
      },
      {
        tag: "【形象推广】",
        tagdes:
          "提升公司口碑与品牌美誉度，坚持“一切为了学员”“变态严管”“师资优质”的核心价值观。",
      },
      {
        tag: "【课程教研】",
        tagdes:
          "负责公司行测方向课程架构设计，跟踪和分析市场、行业情况，研发含量高的课程。",
      },
      {
        tag: "【竞品分析】",
        tagdes:
          "负责分析调研同行竞品机构，深入分析理解并不断挖掘公考考试需求，打造符合千人千面的教学课程。",
      },
    ],
    pay: [
      {
        tag: "【薪酬】",
        tagdes: "30-60K 无责高底薪 +课时费+奖金。",
      },
      {
        tag: "【工作时间】",
        tagdes: "13点-22点，晚上19点到22点讲课，法定节假日放假。",
      },
      {
        tag: "【保险】",
        tagdes: "正规缴纳五险（养老、失业、医疗、工伤、生育五险）。",
      },
      {
        tag: "【环境】",
        tagdes: "办公优雅环境。",
      },
    ],
    need: [
      {
        tag: "【工作经验】",
        tagdes:
          "具有5年以上的公考行业授课经验，必须自身考上过公务员（携带考试成绩），拥有国家、省市考试中心命题经验者优先；有担任各级公考面试考官经历者优先。",
      },
      {
        tag: "【工作试岗】",
        tagdes: "能接受公司内部试岗，考核期2个月。",
      },
      {
        tag: "【学历背景】",
        tagdes: "硕士及以上学历，普通话标准。",
      },
      {
        tag: "【个人素质】",
        tagdes:
          "语言表达流畅，逻辑思维清晰，具备较强的学习能力和创新能力，追求教育理想，热爱教育事业，传递爱与奉献，积极上进，责任心强，有良好的抗压能力，乐于接受挑战。",
      },
    ],
  },

  {
    work: [
      {
        tag: "【直播授课】",
        tagdes: "目前以公务员事业编相关考试内容授课为主",
      },
    ],
    pay: [
      {
        tag: "【薪酬】",
        tagdes: "20K+ 无责高底薪 +课时费+奖金。",
      },
      {
        tag: "【工作时间】",
        tagdes: "13点-22点，晚上19点到22点讲课，法定节假日放假。",
      },
      {
        tag: "【保险】",
        tagdes: "正规缴纳五险（养老、失业、医疗、工伤、生育五险）。",
      },
      {
        tag: "【环境】",
        tagdes: "办公优雅环境。",
      },
    ],
    need: [
      {
        tag: "【工作经验】",
        tagdes: "至少5年以上公考类目教学、培训经验。",
      },
      {
        tag: "【工作试岗】",
        tagdes: "能接受公司内部试岗，考核期2个月。",
      },
      {
        tag: "【学历背景】",
        tagdes: "硕士及以上学历，普通话标准。",
      },
      {
        tag: "【考核要求】",
        tagdes: "拥有成熟的职场素养和优秀的职场习惯，公司会竞调以往教学成果。",
      },
    ],
  },

  {
    work: [
      {
        tag: "【课程研发】",
        tagdes: "为博轩教研实验室做课程研发和优化。",
      },
      {
        tag: "【数据分析】",
        tagdes: "根据学生上课的数据反馈对目前课程系统进行优化。",
      },
    ],
    pay: [
      {
        tag: "【薪酬】",
        tagdes: "面议 无责底薪 +课时费+奖金。",
      },
      {
        tag: "【工作时间】",
        tagdes: "13点-22点，晚上19点到22点讲课，法定节假日放假。",
      },
      {
        tag: "【保险】",
        tagdes: "正规缴纳五险（养老、失业、医疗、工伤、生育五险）。",
      },
      {
        tag: "【环境】",
        tagdes: "办公优雅环境。",
      },
    ],
    need: [
      {
        tag: "【工作经验】",
        tagdes:
          "至少5年以上公考类目教学、培训经验。有公考教研组成员履历优先录取。",
      },
      {
        tag: "【工作试岗】",
        tagdes: "能接受公司内部试岗，考核期2个月。",
      },
      {
        tag: "【学历背景】",
        tagdes: "硕士及以上学历，普通话标准。",
      },
      {
        tag: "【考核要求】",
        tagdes: "能接受最少2个月一次的课程升级要求，公司会进行年度押题率考核。",
      },
    ],
  },

  {
    work: [
      {
        tag: "【协助教学】",
        tagdes:
          "协助同事进行日常综合管理，对教师教学质量进行跟踪、调查及反馈，并提出改进意见。",
      },
      {
        tag: "【课程整理】",
        tagdes:
          "排课、调课；对学生进行日常管理、及时了解学生的学习情况，且辅助教学老师完成课件制作。",
      },
      {
        tag: "【学生管理】",
        tagdes:
          "根据学生的学习目标和学习能力评估，对学员进行专业的学习建议和学习规划。",
      },
      {
        tag: "【定期回访】",
        tagdes:
          "定期对学员进行回访，做好课后反馈及服务工作，有对我们服务不满意的学生，第一时间追责班主任。",
      },
      {
        tag: "【临时任务】",
        tagdes: "完成一定的突发临时性工作，能够接受打电话回访学员。",
      },
    ],
    pay: [
      {
        tag: "【薪酬】",
        tagdes: "8-15K 无责高底薪+奖金。",
      },
      {
        tag: "【工作时间】",
        tagdes: "13点-22点，晚上19点到22点讲课，法定节假日放假。",
      },
      {
        tag: "【保险】",
        tagdes: "正规缴纳五险（养老、失业、医疗、工伤、生育五险）。",
      },
      {
        tag: "【环境】",
        tagdes: "办公优雅环境。",
      },
      {
        tag: "【其他】",
        tagdes: "本岗位为文职岗，不带销售性质！！！",
      },
    ],
    need: [
      {
        tag: "【态度第一】",
        tagdes: "只要你有态度，其他都能在这里学习。",
      },
      {
        tag: "【学历背景】",
        tagdes: "大专及以上学历；有课程顾问经验，或公考方面备考经历者优先。",
      },
    ],
  },
];

export { recruitagf, recruitinfof, recruitags, recruitinfos, jobinfo };
