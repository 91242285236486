<template>
  <div class="preferlessoncard">
    <img
      class="preferimg"
      :src="data.img"
      alt="preferlesson"
    />

    <div class="preferinfo">
      <p class="title">{{ data.title }}</p>
      <div class="lifespan">
        <p class="lifep">{{ data.date }}</p>
      </div>
      <div class="price">
        <div class="icon">
          ￥
          <p class="num">{{ data.price }}</p>
        </div>
        <span>{{ data.people }}人报名</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    data: Object,
    required: true,
  },
};
</script>

<style lang="less" scoped>
.preferlessoncard {
  width: 95vw;
  padding: 10px;
  display: flex;
  box-shadow: 0px 0px 6px 1px rgba(39, 105, 255, 0.1);
  margin-top: 12px;
  .preferimg {
    width: 142px;
    height: 83px;
    border-radius: 6px;
  }
  .preferinfo {
    margin-left: 9px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .title {
      font-size: 14px;
      font-family: PingFang SC-Bold, PingFang SC;
      font-weight: bold;
      color: #141414;
    }

    .lifespan {
      width: 69px;
      height: 21px;
      background: #ffffff;
      border-radius: 4px 4px 4px 4px;
      opacity: 1;
      border: 1px solid #2851f3;
      display: flex;
      justify-content: center;
      align-items: center;
      .lifep {
        font-size: 8px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #1b60ff;
        line-height: 10px;
      }
    }

    .price {
      display: flex;
      width: 180px;
      align-items: flex-end;
      justify-content: space-between;
      .icon {
        display: flex;
        align-items: flex-end;
        font-size: 12px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #1b60ff;
        .num {
          font-size: 18px;
          font-family: PingFang SC-Bold, PingFang SC;
          font-weight: bold;
          color: #1b60ff;
          line-height: 17px;
        }
      }
      span {
        font-size: 10px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #b9b9b9;
      }
    }
  }
}
</style>
