<template>
  <div class="lessoninfo">
    <img
      class="lessoninfoimg"
      :src="thelessoninfo.lessoninfoimg"
      alt="lessoninfo"
    />

    <div class="footer">
      <div class="paytabber">
        <button
          v-if="!thelessoninfo.limit"
          class="buybtn"
          @click="topay(routercode)"
        >
          确认报名
        </button>
        <button v-else class="buybtn" @click="topay(routercode, anchor)">
          免费报名
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { preferlessons, hotlessons } from "@/tempdata/home/homelessons";
import { bxlessons } from "@/tempdata/lesson/lessons";
import { Icon } from "vant";
export default {
  components: {
    [Icon.name]: Icon,
  },
  data() {
    return {
      thelessoninfo: {},

      /**/
      routercode: "",
      anchor: "",
    };
  },
  created() {
    this.setleesoninfo();
  },
  mounted() {
    this.$parent.$el.childNodes[0].scrollTop = 0;
  },
  methods: {
    /* url解析 */
    setleesoninfo() {
      const code = this.$route.query.code || "M0xg";
      const anchor = this.$route.query.anchor || "Oxa3L";

      this.routercode = code;
      this.anchor = anchor;
      // this.routertype=type;

      // if (type == "prefer") {
      //   for (const item of preferlessons) {
      //     if (code == item.paycode) {
      //       this.thelessoninfo = item;
      //       return;
      //     }
      //   }
      // }

      // if (type == "hot") {
      //   for (const item of hotlessons) {
      //     if (code == item.paycode) {
      //       this.thelessoninfo = item;
      //       return;
      //     }
      //   }
      // }

      // if (type == "all") {
      for (const item of bxlessons) {
        console.log(item);
        if (code == item.paycode) {
          this.thelessoninfo = item;
          console.log(item);
          return;
        }
      }
      // }
    },

    /**/
    topay(code, anchor) {
      // console.log(code);
      if ((anchor && code == "EDDn") || (anchor && code == "M0xg")) {
        this.$router.push({ name: "pubcourseinfo", query: { anchor } });
      } else if (anchor) {
        this.$router.push({ name: "pay", query: { code, anchor } });
      } else {
        this.$router.push({ name: "pay", query: { code } });
      }
    },
  },
};
</script>

<style lang="less" scoped>
.lessoninfo {
  width: 100vw;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  .lessonimg {
    width: 100vw;
    object-fit: contain;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }
  .lessonbox {
    width: 100%;
    padding: 10px;
    display: flex;
    align-items: center;
    .tagshow {
      width: 150px;
      height: 30px;
      display: flex;
      justify-content: space-around;
      align-items: center;
      border-radius: 8px;
      background-image: linear-gradient(-20deg, #6143a1 0%, #5ab4e8 100%);
      position: relative;
      p {
        font-size: 10px;
        color: #fff;
        font-style: italic;
        line-height: 10px;
      }
    }

    .tagshowsdes {
      font-size: 12px;
      color: #abb8c5;
      margin-left: 12px;
    }

    .peopecount {
      font-size: 12px;
      color: #8c97a2;
      margin-left: 6px;
      position: absolute;
      right: 10px;
    }
  }
  .lessontitile {
    margin-left: 12px;
    margin-bottom: 10px;
    font-size: 24px;
  }
  .lessoninfoimg {
    margin-bottom: 52px;
  }

  .footer {
    position: fixed;
    width: 100%;
    padding: 10px 5px;
    bottom: 0;
    z-index: 10;
    background: #fff;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    display: flex;
    align-items: center;
    .paytabber {
      width: 100%;
      display: flex;
      justify-content: space-around;
      align-items: center;
      .buybtn {
        width: 358px;
        height: 51px;
        background: linear-gradient(180deg, #066cff 0%, #217bfe 100%);
        border-radius: 26px 26px 26px 26px;
        font-size: 18px;
        font-family: PingFang SC-Bold, PingFang SC;
        font-weight: bold;
        color: #ffffff;
        display: flex;
        justify-content: center;
        align-items: center;
        border: none;
      }
      .icon {
        display: flex;
        flex-direction: column;
        align-items: center;
      }
    }
  }
}

/* 通用部分 */
.title {
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #277dcd;
  position: relative;
  height: 14px;
  display: flex;
  align-items: center;
  font-size: 14px;
  margin-bottom: 12px;
}
.title::before {
  content: "";
  display: inline-block;
  width: 6px;
  height: 14px;
  background: #1e62a1;
  border-radius: 12px;
  position: relative;
  margin: 0 3px 0 10px;
}
</style>
