<template>
  <div class="about" ref="box">
    <theheader></theheader>

    <div class="topimg">
      <div class="ani">
        <p class="title">博轩公考</p>
        <p class="des">
          博轩科技有限公司，是中国职业教育培训服务供应商，专注于提升职业教育学员上岸率，我们研发出一套高效的考试培训产品及完成的服务流程，通过高质量的直播形式进行课程的在线培训，加上整套完整的在线学习工具及资料，给在公共职务和事业单位以及其他若干专业和行业发展的成人学员提供全套的招录和资格考试培训课程。
          博轩也在积极寻求合作，以达到提供更优质服务的目标！我们已经将“博轩公考”打造成为了中国职业考试培训行业的知名品牌。
          曾获2022抖音公考类目占有量第一；2022抖音生态最佳合作伙伴；腾讯教育年度公考类目甄选机构
        </p>
      </div>
    </div>

    <!-- <img class="topimg" src="../../assets/img/about/kv.png" alt="kv" /> -->

    <div class="tagcontent">
      <div class="item">
        <img
          src="https://oss.oxke.net/boxuanedu-m/about/1.png"
          alt="tag"
          class="tagimg"
        />
        <div class="info">
          <p class="p1">线下积累</p>
          <p class="p2">10余年专业公考培训经验</p>
        </div>
      </div>

      <div class="item">
        <img
          src="https://oss.oxke.net/boxuanedu-m/about/2.png"
          alt="tag"
          class="tagimg"
        />
        <div class="info">
          <p class="p1">专业团队</p>
          <p class="p2">200+专业教研团队</p>
        </div>
      </div>

      <div class="item">
        <img
          src="https://oss.oxke.net/boxuanedu-m/about/3.png"
          alt="tag"
          class="tagimg"
        />
        <div class="info">
          <p class="p1">经验丰富</p>
          <p class="p2">线上+线下直播</p>
        </div>
      </div>

      <div class="item">
        <img
          src="https://oss.oxke.net/boxuanedu-m/about/4.png"
          alt="tag"
          class="tagimg"
        />
        <div class="info">
          <p class="p1">口碑优良</p>
          <p class="p2">上岸学员5000+</p>
        </div>
      </div>
    </div>

    <div class="commontitle1">
      <p class="shadowtext">Company precipitation</p>
      <p class="maintext">公司沉淀</p>
      <p class="info">
        博轩长期致力于成人考试培训领域，博轩积累多年，帮助众多考生通过考试
      </p>
    </div>

    <img
      src="https://oss.oxke.net/boxuanedu-m/about/bg1"
      alt="common"
      class="commonimg1"
    />

    <div class="upline">
      <h3 class="title">线下积累</h3>
      <p class="info">
        10余年专业公考培训经验，线下20+城市直营教学经验，每10个学生就有8个来自口碑推荐，现在全面转型，通过线上+线下结合，把更多的成本用在和课程及服务端，以降低学员的入学成本，同时通过高效的考试培训产品及完成的服务流程，辅助整套完整的在线学习工具及资料，提高学员的上岸率。
      </p>
    </div>

    <div class="bluebg">
      <div class="commontitle2">
        <p class="shadowtext">professional team</p>
        <p class="maintext">专业团队</p>
        <p class="info">
          200+专职教研团队，行业最苛刻的教师招聘条件，最低教学经验5年起
        </p>
      </div>

      <div class="goodcontent">
        <div class="item">
          <div class="upp">
            <span class="big">200+</span>
            <span class="com">名</span>
          </div>
          <p class="bottomp">公考讲师</p>
        </div>
        <div class="item">
          <div class="upp">
            <span class="big">10</span>
            <span class="com">年+</span>
          </div>
          <p class="bottomp">授课经验</p>
        </div>
        <div class="item">
          <div class="upp">
            <span class="big">10000+</span>
            <span class="com">小时</span>
          </div>
          <p class="bottomp">授课时长</p>
        </div>
        <div class="item">
          <div class="upp">
            <span class="big">5 </span>
            <span class="com">年</span>
          </div>
          <p class="bottomp">连续0投诉</p>
        </div>
      </div>
    </div>

    <div class="upline">
      <h3 class="title">经验沉淀</h3>
      <p class="info">
        通过线上+线下直播，平台累计学习人数100w+，线上公益直播每日在线人数；同时我们也在打造博轩自己的产品库。
      </p>
    </div>

    <div class="bookcontent">
      <div class="content">
        <div class="item">
          <img
            src="https://oss.oxke.net/boxuanedu-m/about/bookimg/1.png"
            alt="bookimg"
          />
          <p>博轩公考/编著</p>
          <p>高等教育出版社</p>
        </div>

        <div class="item">
          <img
            src="https://oss.oxke.net/boxuanedu-m/about/bookimg/2.png"
            alt="bookimg"
          />
          <p>博轩公考/编著</p>
          <p>高等教育出版社</p>
        </div>
        <div class="item">
          <img
            src="https://oss.oxke.net/boxuanedu-m/about/bookimg/3.png"
            alt="bookimg"
          />
          <p>博轩公考/编著</p>
          <p>高等教育出版社</p>
        </div>
        <div class="item">
          <img
            src="https://oss.oxke.net/boxuanedu-m/about/bookimg/4.png"
            alt="bookimg"
          />
          <p>博轩公考/编著</p>
          <p>高等教育出版社</p>
        </div>
        <div class="item">
          <img
            src="https://oss.oxke.net/boxuanedu-m/about/bookimg/5.png"
            alt="bookimg"
          />
          <p>博轩公考/编著</p>
          <p>高等教育出版社</p>
        </div>
        <div class="item">
          <img
            src="https://oss.oxke.net/boxuanedu-m/about/bookimg/6.png"
            alt="bookimg"
          />
          <p>博轩公考/编著</p>
          <p>高等教育出版社</p>
        </div>
        <div class="item">
          <img
            src="https://oss.oxke.net/boxuanedu-m/about/bookimg/7.png"
            alt="bookimg"
          />
          <p>博轩公考/编著</p>
          <p>高等教育出版社</p>
        </div>
        <div class="item">
          <img
            src="https://oss.oxke.net/boxuanedu-m/about/bookimg/8.png"
            alt="bookimg"
          />
          <p>博轩公考/编著</p>
          <p>高等教育出版社</p>
        </div>
      </div>
    </div>

    <div class="upline">
      <h3 class="title">口碑优良</h3>
      <vantable :option="option" :tableData="tableData"></vantable>
    </div>

    <div class="commontitle1">
      <p class="shadowtext">Classmate's words</p>
      <p class="maintext">同学的话</p>
      <p class="info">
        博轩长期致力于成人考试培训领域，博轩积累多年，帮助众多考生通过考试
      </p>
    </div>

    <div class="stuwordscontent">
      <div class="left">
        <div class="item">
          <img
            class="yinhao"
            src="https://oss.oxke.net/boxuanedu-m/about/point.png"
            alt="yinhao"
          />
          <p>
            感谢小路老师的指导，让我能顺利通过面试，最后成功上岸！选择博轩是值得的选择，我的成功离不开老师帮助。
          </p>
          <div class="user">
            <img src="https://oss.oxke.net/boxuanedu-m/about/p1" alt="user" />
            <p>@爱吃猫的鱼</p>
          </div>
        </div>
      </div>

      <div class="right">
        <div class="item">
          <img class="yinhao" src="https://oss.oxke.net/boxuanedu-m/about/point.png" alt="yinhao" />
          <p>
            博轩的课程对我来说很有作用，老师帮我在最后的时间进行排课和指导，并且给予精神上的支持，这对我来说很有帮助。
          </p>
          <div class="user">
            <img src="https://oss.oxke.net/boxuanedu-m/about/p2" alt="user" />
            <p>@小麦</p>
          </div>
        </div>
      </div>

      <div class="left">
        <div class="item">
          <img class="yinhao" src="https://oss.oxke.net/boxuanedu-m/about/point.png" alt="yinhao" />
          <p>
            还可以，有时候赶不上直播课还有录播课程，老师会对问题进行答疑，总的来说还是有收获的。
          </p>
          <div class="user">
            <img src="https://oss.oxke.net/boxuanedu-m/about/p3" alt="user" />
            <p>@aattsda</p>
          </div>
        </div>
      </div>
    </div>

    <div class="commontitle1">
      <p class="shadowtext">support boxuan</p>
      <p class="maintext">支持博轩</p>
      <p class="info">
        博轩长期致力于成人考试培训领域，博轩积累多年，帮助众多考生通过考试
      </p>
    </div>

    <div class="buycontent">
      <van-swipe class="imgch" indicator-color="black">
        <van-swipe-item>
          <img class="buyimg" src="https://oss.oxke.net/boxuanedu-m/about/buy1.png" alt="buy" />
          <div class="buyinfo">
            <div class="title">
              <p class="left">试听课程</p>
              <p class="right">免费</p>
            </div>
            <div class="content">
              <p>了解博轩课程内容</p>
              <p>获取老师选课指导</p>
              <P>专业的考试建议</P>
            </div>

            <button class="btn3">开始使用</button>
          </div>
        </van-swipe-item>

        <van-swipe-item
          ><img
            class="buyimg"
            src="https://oss.oxke.net/boxuanedu-m/about/buy2.png"
            alt="buy"
          />
          <div class="buyinfo">
            <div class="title">
              <p class="left">热门课程</p>
              <p class="right">￥79 起</p>
            </div>
            <div class="content">
              <p>排列课程，让您的时间分配更合理</p>
              <p>课程提醒，不要忘记学习哟</p>
              <P>秒杀技巧，提高刷题速度</P>
            </div>
            <button class="btn1">开始使用</button>
          </div></van-swipe-item
        >

        <van-swipe-item
          ><img
            class="buyimg"
            src="https://oss.oxke.net/boxuanedu-m/about/buy3.png"
            alt="buy"
          />
          <div class="buyinfo">
            <div class="title">
              <p class="left">系统课程</p>
              <p class="right">￥79 起</p>
            </div>
            <div class="content">
              <p>系统化学习，让您不再迷茫</p>
              <p>一对一辅导在线答疑</p>
              <P>针对性训练，让您快速提高分数</P>
              <P>暖心补贴，考试未通过提供退费服务</P>
            </div>
            <button class="btn1">开始使用</button>
          </div></van-swipe-item
        >
      </van-swipe>
    </div>

    <div class="commontitle1">
      <p class="shadowtext">common problem</p>
      <p class="maintext">常见问题</p>
      <p class="info">
        博轩长期致力于成人考试培训领域，博轩积累多年，帮助众多考生通过考试
      </p>
    </div>

    <div class="answercontent">
      <div class="left">
        <img class="use" src="https://oss.oxke.net/boxuanedu-m/about/icon1" alt="use" />
        <div class="dialogbox">
          <div class="dialogattch">
            <div class="dialogattchinvert"></div>
          </div>
          <div class="dialogmain">你们的优势在哪里？</div>
        </div>
      </div>
      <div class="right">
        <div class="dialogboxright">
          <div class="dialogattch">
            <div class="dialogattchinvert"></div>
          </div>
          <div class="dialogmain">
            1.教学服务:机构提供答疑、督促服务，，地教研为本地学生集中答题，难题有问必答，总结高频易错点结学生的基础，分层定制复习规划;每个学生都配备了专属的班主任、手机、微信监督员答疑，提供课程进度利备考信息。
            <br />
            2,教学方式:机构教学采用线下面授和线上网络相结合的模式，对学员进行教学量身定制，致力让学员能到针对性的学习，从而达到预期的目标,提倡理论结合实践的双向教学，课程由浅入深，课后为学生提供个化的答疑指导，提高学习效果。
            <br />
            3.师资力量:机构为确保教学的质量，重磅加入总裁教学，教学团有丰富的教学经验教学思路清晰易懂教学方法新颖，解决问题的能力灵活。
          </div>
        </div>
        <img class="use" src="https://oss.oxke.net/boxuanedu-m/about/icon2" alt="use" />
      </div>

      <div class="left">
        <img class="use" src="https://oss.oxke.net/boxuanedu-m/about/icon1" alt="use" />
        <div class="dialogbox">
          <div class="dialogattch">
            <div class="dialogattchinvert"></div>
          </div>
          <div class="dialogmain">合同在哪里签署？</div>
        </div>
      </div>
      <div class="right">
        <div class="dialogboxright">
          <div class="dialogattch">
            <div class="dialogattchinvert"></div>
          </div>
          <div class="dialogmain">
            通过法大大签署；法大大是国内领先的电子合同的电子签署云服务平台，致力于为企业，政府和个人提供合法的数字签名技术服务和电子合同，电子单据的在线协同签署及管理服务。
          </div>
        </div>
        <img class="use" src="https://oss.oxke.net/boxuanedu-m/about/icon2" alt="use" />
      </div>

      <div class="left">
        <img class="use" src="https://oss.oxke.net/boxuanedu-m/about/icon1" alt="use" />
        <div class="dialogbox">
          <div class="dialogattch">
            <div class="dialogattchinvert"></div>
          </div>
          <div class="dialogmain">如何学习我们的课程？</div>
        </div>
      </div>
      <div class="right">
        <div class="dialogboxright">
          <div class="dialogattch">
            <div class="dialogattchinvert"></div>
          </div>
          <div class="dialogmain">
            可以用手机，电脑或ipad登录个人账户，通过腾慧云，以及独家学习app，在线进行学习。
          </div>
        </div>
        <img class="use" src="https://oss.oxke.net/boxuanedu-m/about/icon2" alt="use" />
      </div>

      <div class="left">
        <img class="use" src="https://oss.oxke.net/boxuanedu-m/about/icon1" alt="use" />
        <div class="dialogbox">
          <div class="dialogattch">
            <div class="dialogattchinvert"></div>
          </div>
          <div class="dialogmain">课程是怎样安排的？</div>
        </div>
      </div>
      <div class="right">
        <div class="dialogboxright">
          <div class="dialogattch">
            <div class="dialogattchinvert"></div>
          </div>
          <div class="dialogmain">
            新生入学后，会有班主任一对一为学员指定个人学习计划，因为有的学员忙有的学员比较空闲，我们会根据个人情况安排适合学员的学习计划。
          </div>
        </div>
        <img class="use" src="https://oss.oxke.net/boxuanedu-m/about/icon2" alt="use" />
      </div>

      <div class="left">
        <img class="use" src="https://oss.oxke.net/boxuanedu-m/about/icon1" alt="use" />
        <div class="dialogbox">
          <div class="dialogattch">
            <div class="dialogattchinvert"></div>
          </div>
          <div class="dialogmain">有合适我的报考岗位吗？</div>
        </div>
      </div>
      <div class="right">
        <div class="dialogboxright">
          <div class="dialogattch">
            <div class="dialogattchinvert"></div>
          </div>
          <div class="dialogmain">
            在报名学习之前，会有选岗老师提供针对性的指导，没有合适学员的报考岗位，我们不会进行后续的收费服务。
          </div>
        </div>
        <img class="use" src="https://oss.oxke.net/boxuanedu-m/about/icon2" alt="use" />
      </div>

      <div class="left">
        <img class="use" src="https://oss.oxke.net/boxuanedu-m/about/icon1" alt="use" />
        <div class="dialogbox">
          <div class="dialogattch">
            <div class="dialogattchinvert"></div>
          </div>
          <div class="dialogmain">有课后习题吗？</div>
        </div>
      </div>
      <div class="right">
        <div class="dialogboxright">
          <div class="dialogattch">
            <div class="dialogattchinvert"></div>
          </div>
          <div class="dialogmain">
            有题库可以刷题，而且课后会安排作业，在安排习题后，需要学员在三天内提交，班主任会批改。
          </div>
        </div>
        <img class="use" src="https://oss.oxke.net/boxuanedu-m/about/icon2" alt="use" />
      </div>
    </div>

    <myfooter></myfooter>
  </div>
</template>

<script>
import vantable from "./vantable.vue";
import theheader from "../headfooter/head.vue";
import myfooter from "../headfooter/foot.vue";
import { Swipe, SwipeItem } from "vant";
export default {
  components: {
    vantable,
    theheader,
    myfooter,
    [Swipe.name]: Swipe,
    [SwipeItem.name]: SwipeItem,
  },
  data() {
    return {
      tableData: [
        {
          name: "王*",
          stu: "本科",
          pos: "学生",
          stupos: "中共******办公室",
          socre: "132.5",
          address: "厦门",
        },
        {
          name: "周*乐",
          stu: "硕士",
          pos: "算法工程师",
          stupos: "中共******机关",
          socre: 140.0,
          address: "广州",
        },
        {
          name: "朱*",
          stu: "博士",
          pos: "老师",
          stupos: "共青图****省委",
          socre: 128.5,
          address: "上海",
        },
        {
          name: "汤*琴",
          stu: "本科",
          pos: "财务",
          stupos: "*****省档案馆",
          socre: 132.0,
          address: "北京",
        },
        {
          name: "李*超",
          stu: "本科",
          pos: "宝妈",
          stupos: "***科学技术协会",
          socre: 129.0,
          address: "河南",
        },
        {
          name: "钟*",
          stu: "大专",
          pos: "自由职业",
          stupos: "*****省教育厅",
          socre: 127.5,
          address: "福建",
        },
        {
          name: "郑*希",
          stu: "本科",
          pos: "宝妈",
          stupos: "*****省水利局",
          socre: 133.5,
          address: "天津",
        },
        {
          name: "王*海",
          stu: "本科",
          pos: "个体户",
          stupos: "*****省体育局",
          socre: 132.5,
          address: "青岛",
        },
        {
          name: "潘*元",
          stu: "专科",
          pos: "学生",
          stupos: "*省退役军人**",
          socre: 122.5,
          address: "汕头",
        },
        {
          name: "张*",
          stu: "专科",
          pos: "人事",
          stupos: "*市场监督管理局",
          socre: 119.5,
          address: "荆门",
        },
        {
          name: "李*",
          stu: "专科",
          pos: "程序",
          stupos: "*劳动局",
          socre: 109.5,
          address: "天门",
        },
        {
          name: "张*可",
          stu: "本科",
          pos: "人事",
          stupos: "***税务管理局",
          socre: 112.5,
          address: "武汉",
        },
        {
          name: "周*军",
          stu: "本科",
          pos: "产品",
          stupos: "***公安局",
          socre: 117,
          address: "长春",
        },
        {
          name: "王*",
          stu: "本科",
          pos: "自由职业者",
          stupos: "***教育局",
          socre: 110,
          address: "广州",
        },
      ],
      //th
      option: {
        column: [
          {
            label: "学员姓名",
            tableDataprop: "name",
          },
          {
            label: "学历",
            tableDataprop: "stu",
          },
          {
            label: "学习前职位",
            tableDataprop: "pos",
          },
          {
            label: "报考岗位",
            tableDataprop: "stupos",
          },
          {
            label: "上岸分数",
            tableDataprop: "socre",
          },
        ],
        option: {
          column: [
            {
              label: "学员姓名",
              tableDataprop: "name",
            },
            {
              label: "学历",
              tableDataprop: "stu",
            },
            {
              label: "学习前职位",
              tableDataprop: "pos",
            },
            {
              label: "赠送",
              tableDataprop: "stupos",
            },
            {
              label: "上岸分数",
              tableDataprop: "socre",
            },
          ],
        },
      },
    };
  },
  mounted() {
    this.firsttitle();
    window.addEventListener("scroll", this.handlescroll, true);

    // 默认跳转到页头
    const dom = this.$refs.box;
    this.$parent.$el.childNodes[0].scrollTop = 0;
  },
  methods: {
    firsttitle() {
      let clientHeight =
        window.innerHeight ||
        document.documentElement.clientHeight ||
        document.body.clientHeight;
      if (clientHeight <= 677) {
        return;
      } else {
        const thetitledom = document.querySelectorAll(".commontitle1")[0];
        console.log(thetitledom);
        this.rendertitle(thetitledom);
      }
    },
    /* 整体滚动动画 */
    handlescroll() {
      const dom = this.$refs.box;
      const realscolltop = this.$parent.$el.childNodes[0].scrollTop;
      if (realscolltop >= 20) {
        const thetitledom = document.querySelectorAll(".commontitle1")[0];
        this.rendertitle(thetitledom);
      }
      if (realscolltop >= 350) {
        const thetitledom = document.querySelectorAll(".commontitle2")[0];
        const goodcontentdom = document.querySelector(".goodcontent");
        this.rendertitle2(thetitledom);
        goodcontentdom.className = "goodcontent goodcontentrender";
      }
      if (realscolltop >= 940) {
        const bookcontentdom = document.querySelector(".bookcontent");
        const childlist = bookcontentdom.childNodes[0].childNodes;
        let delay = 100;
        this.delaybookrender(childlist, 0, delay);
      }
      if (realscolltop >= 1320) {
        const thetitledom = document.querySelectorAll(".commontitle1")[1];
        this.rendertitle(thetitledom);
      }
      if (realscolltop >= 1688) {
        const thestuwordscontentdom =
          document.querySelector(".stuwordscontent");
        const [one, two, three] = [...thestuwordscontentdom.childNodes];
        one.className = "left leftenterender";
        two.className = "right rightenterender";
        three.className = "left leftenterender";
      }
      if (realscolltop >= 1720) {
        const thetitledom = document.querySelectorAll(".commontitle1")[2];
        this.rendertitle(thetitledom);
      }
      if (realscolltop >= 2080) {
        const thetitledom = document.querySelectorAll(".commontitle1")[3];
        this.rendertitle(thetitledom);
      }
      if (realscolltop >= 2180) {
        const theanswercontentdom = document.querySelector(".answercontent");
        const list = theanswercontentdom.childNodes;
        list[0].className = "left leftenterender";
        list[1].className = "right rightenterender";
      }
      if (realscolltop >= 2620) {
        const theanswercontentdom = document.querySelector(".answercontent");
        const list = theanswercontentdom.childNodes;
        list[2].className = "left leftenterender";
        list[3].className = "right rightenterender";
      }
      if (realscolltop >= 2820) {
        const theanswercontentdom = document.querySelector(".answercontent");
        const list = theanswercontentdom.childNodes;
        list[4].className = "left leftenterender";
        list[5].className = "right rightenterender";
      }
      if (realscolltop >= 3020) {
        const theanswercontentdom = document.querySelector(".answercontent");
        const list = theanswercontentdom.childNodes;
        list[6].className = "left leftenterender";
        list[7].className = "right rightenterender";
      }
      if (realscolltop >= 3220) {
        const theanswercontentdom = document.querySelector(".answercontent");
        const list = theanswercontentdom.childNodes;
        list[8].className = "left leftenterender";
        list[9].className = "right rightenterender";
      }
      if (realscolltop >= 3320) {
        const theanswercontentdom = document.querySelector(".answercontent");
        const list = theanswercontentdom.childNodes;
        list[10].className = "left leftenterender";
        list[11].className = "right rightenterender";
      }
    },

    rendertitle(dom) {
      setTimeout(() => {
        dom.className = "titlerender commontitle1";
      }, 10);
    },
    rendertitle2(dom) {
      setTimeout(() => {
        dom.className = "titlerender commontitle2";
      }, 10);
    },
    delaybookrender(list, index, delay) {
      if (index > list.length) {
        return;
      } else {
        list[0].className = "item bookcontentrender";
        setTimeout(() => {
          if (list[index]) {
            list[index].className = "item bookcontentrender";
          }
        }, delay);

        ++index;
        delay += 100;
        this.delaybookrender(list, index, delay);
      }
    },
  },
  beforeDestroy() {},
  destroyed() {
    window.removeEventListener("scroll", this.handlescroll, true);
  },
};
</script>

<style lang="less" scoped>
.about {
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  .topimg {
    background: url('https://oss.oxke.net/boxuanedu-m/about/kv.png');
    height: calc(100vw / 0.74);
    margin-top: 48px;
    width: 100%;
    background-size: contain;
    display: flex;
    flex-direction: column;

    padding: 30px 14px 0 14px;
    .title {
      font-size: 22px;
      font-weight: bold;
      color: #fff;
    }
    .title::after {
      content: "";
      display: block;
      width: 60px;
      height: 5px;
      background: #35c8ff;
      margin-top: 10px;
      margin-left: 3px;
      border-radius: 1px;
    }
    .des {
      margin-top: 12px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #ffffff;
      line-height: 20px;
      font-size: 12px;
    }
  }
  .tagcontent {
    display: flex;
    width: 351px;
    height: 110px;
    background: rgba(255, 255, 255, 1);

    box-shadow: 0px 0px 32px 0px rgba(18, 87, 242, 0.15);
    border-radius: 15px;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-end;
    position: relative;
    top: -25px;
    padding-top: 5px;
    transform: scale(0);
    transition: all 1s linear;
    animation: topcontentredner 1s linear 1s forwards;
    .item {
      display: flex;
      width: 155px;
      height: 32px;
      .tagimg {
        width: 35px;
        height: 30px;
        margin-right: 10px;
        animation: redertagimg 1s linear 2s forwards;
      }
      .info {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        .p1 {
          font-size: 12px;
          font-family: Microsoft YaHei;
          font-weight: bold;
          color: #000000;
        }
        .p2 {
          font-size: 9px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #959595;
          zoom: 80%;
        }
      }
    }
  }

  .commonimg1 {
    width: 90%;
    object-fit: cover;
    margin-bottom: 12px;
  }

  .upline {
    width: 90%;
    display: flex;
    flex-direction: column;
    margin-bottom: 12px;
    .title {
      font-size: 13px;
      font-family: Source Han Sans CN;
      font-weight: 500;
      color: #103ff7;
      margin-bottom: 8px;
    }
    .title::before {
      content: "";
      display: inline-block;
      width: 3px;
      height: 12px;
      background: #103ff7;
      margin-right: 5px;
      border-radius: 4px;
      position: relative;
      top: 1px;
    }
    .info {
      font-size: 10px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #141414;
      line-height: 15px;
    }
  }

  .bluebg {
    width: 100%;
    height: 255px;
    background: url("https://oss.oxke.net/boxuanedu-m/about/bg2");
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 10px;
    .goodcontent {
      display: flex;
      width: 70%;
      flex-wrap: wrap;
      justify-content: space-between;
      opacity: 0;
      .item {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-right: 20px;
        margin-bottom: 10px;
        .upp {
          .big {
            font-size: 25px;
            font-family: DIN;
            font-weight: bold;
            color: #ffffff;
          }
          .com {
            font-size: 18px;
            font-family: Source Han Sans CN;
            font-weight: 500;
            color: #ffffff;
          }
        }
        .bottomp {
          font-size: 11px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #ffffff;
        }
      }
    }
  }

  .bookcontent {
    width: 100%;
    height: 305px;
    background: url("https://oss.oxke.net/boxuanedu-m/about/bookbg.png");
    background-size: contain;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    margin-bottom: 10px;
    .content {
      width: 90%;
      height: 270px;
      background: #ffffff;
      box-shadow: 0px 0px 16px 0px rgba(78, 98, 165, 0.25);
      border-radius: 10px;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
      .item {
        display: flex;
        align-items: center;
        flex-direction: column;
        font-size: 9px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #6f6f6f;
        img {
          width: 80px;
        }
      }
    }
  }

  .stuwordscontent {
    width: 90%;
    margin-top: 12px;
    font-size: 10px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #000000;
    line-height: 15px;
    .left {
      width: 100%;
      display: flex;
      margin-bottom: 10px;
      position: relative;
      opacity: 0;
      .item {
        width: 80%;
        background: #ffffff;
        box-shadow: 0px 3px 7px 0px rgba(39, 95, 240, 0.22);
        border-radius: 10px;
        padding: 5px;
        .yinhao {
          width: 8px;
          height: 7px;
          margin-bottom: 5px;
        }
        .user {
          display: flex;
          align-items: center;
          margin-top: 7px;
          margin-bottom: 5px;
          img {
            width: 18px;
            height: 18px;
          }
          p {
            margin-left: 4px;
            font-size: 9px;
            font-family: DIN;
            font-weight: bold;
            color: #2a3dec;
          }
        }
      }
    }

    .right {
      width: 100%;
      display: flex;
      margin-bottom: 10px;
      justify-content: flex-end;
      position: relative;
      opacity: 0;
      .item {
        width: 80%;
        background: #ffffff;
        box-shadow: 0px 3px 7px 0px rgba(39, 95, 240, 0.22);
        border-radius: 10px;
        padding: 5px;
        .yinhao {
          width: 8px;
          height: 7px;
          margin-bottom: 5px;
        }
        .user {
          display: flex;
          align-items: center;
          margin-top: 7px;
          margin-bottom: 5px;
          img {
            width: 18px;
            height: 18px;
          }
          p {
            margin-left: 4px;
            font-size: 9px;
            font-family: DIN;
            font-weight: bold;
            color: #2a3dec;
          }
        }
      }
    }
  }

  .buycontent {
    width: 100%;
    height: 272px;
    background: #f5f5f5;
    margin-top: 10px;

    ::v-deep .imgch .van-swipe-item {
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      top: 20px;
    }
  }

  .answercontent {
    width: 90%;
    margin-top: 10px;
    margin-bottom: 20px;
    .left {
      display: flex;
      margin-bottom: 24px;
      align-items: flex-end;
      margin-top: 10px;
      opacity: 0;
      position: relative;
      .use {
        width: 36px;
        height: 36px;
        object-fit: contain;
      }
      .words {
        width: 50%;
        object-fit: contain;
      }
    }
    .right {
      display: flex;
      justify-content: flex-end;
      align-items: flex-end;
      margin-bottom: 24px;
      opacity: 0;
      position: relative;
      .use {
        width: 36px;
        height: 36px;
        object-fit: contain;
      }
      .words {
        width: 90%;
        object-fit: contain;
      }
    }
  }
}

/*通用 */
.commontitle1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 25px;
  position: relative;
  opacity: 0;
  .shadowtext {
    font-size: 19px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #efefef;
  }
  .maintext {
    font-size: 15px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #191919;
    position: relative;
    top: -8px;
  }
  .maintext::before {
    content: "";
    display: inline-block;
    width: 26px;
    height: 1px;
    background: #000000;
    position: relative;
    top: -5px;
    margin-right: 10px;
  }
  .maintext::after {
    content: "";
    display: inline-block;
    width: 26px;
    height: 1px;
    background: #000000;
    position: relative;
    top: -5px;
    margin-left: 10px;
  }
  .info {
    font-size: 10px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #3a3a3a;
    zoom: 0.85;
  }
  .info::after {
    content: "";
    display: block;
    width: 34px;
    height: 4px;
    background: #dddddd;
    position: relative;
    left: 45%;
    margin-top: 10px;
  }
}

.commontitle2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 18px 25px;
  opacity: 0;
  position: relative;
  .shadowtext {
    font-size: 19px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #103ff7;
  }
  .maintext {
    font-size: 15px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #fff;
    position: relative;
    top: -8px;
  }

  .info {
    font-size: 10px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #fff;
    zoom: 0.85;
  }
  .info::after {
    content: "";
    display: block;
    width: 34px;
    height: 4px;
    background: #fff;
    position: relative;
    left: 45%;
    margin-top: 10px;
  }
}

.buyimg {
  height: 252px;
  object-fit: contain;
  position: relative;
}

.buyinfo {
  width: 60%;
  position: absolute;
  top: 17px;
  .title {
    display: flex;
    justify-content: space-between;
    font-size: 13px;
    font-family: Source Han Sans CN;
    font-weight: bold;
    color: #ffffff;
    margin-bottom: 20px;
  }
  .content {
    font-size: 11px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #3f3f3f;
    margin-bottom: 30px;

    P {
      margin-bottom: 15px;

      margin-left: 5px;
    }
    p::before {
      content: "";
      display: inline-block;
      width: 6px;
      height: 6px;
      background: rgba(52, 196, 250, 1);
      border-radius: 50%;
      margin-right: 9px;
      position: relative;
      top: -1px;
    }
  }
  .btn1 {
    width: 100%;
    height: 29px;
    border: 2px solid;
    background: linear-gradient(0deg, #4079ff, #31b4ff);
    box-shadow: 0px 3px 7px 0px rgba(39, 95, 240, 0.3);
    border-radius: 25px;
    font-size: 10px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #ffffff;
  }

  .btn2 {
    width: 100%;
    height: 29px;
    border: 2px solid;
    background: linear-gradient(0deg, #2831f2, #1b7af0);
    box-shadow: 0px 3px 7px 0px rgba(39, 95, 240, 0.3);
    border-radius: 25px;
    font-size: 10px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #ffffff;
  }

  .btn3 {
    width: 100%;
    height: 29px;
    border: 2px solid;
    background: #78ebff;
    box-shadow: 0px 3px 7px 0px rgba(39, 95, 240, 0.3);
    border-radius: 25px;
    font-size: 10px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #ffffff;
  }
}

/* 通用样式 可入样式组件 */

/* 对话框 */
.dialogbox {
  display: flex;
  align-items: flex-end;
  .dialogmain {
    height: 36px;
    background: linear-gradient(80deg, #2479f8, #103ff7);
    position: relative;
    border-radius: 4px;
    padding-left: 6px;
    font-size: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
  }
  .dialogattch {
    width: 18px;
    height: 19px;
    background: #fff;
    border-radius: 0px 0 30px 0;
    border: none;
    position: relative;
    display: flex;
    top: -0.5px;
    .dialogattchinvert {
      width: 20px;
      height: 19px;
      background: #2479f8;
      position: absolute;
      left: 1px;
      top: 0.5px;
      z-index: -1;
      border-right: none;
      border-top: none;
      overflow: hidden;
    }
  }
}

.dialogboxright {
  display: flex;
  align-items: flex-end;
  transform: rotateY(180deg);
  .dialogmain {
    width: 242px;
    background: #fff;
    position: relative;
    border-radius: 4px;
    z-index: -100;
    padding: 4px;
    border: 1px solid rgba(85, 178, 246, 1);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    line-height: 18px;
    transform: rotateY(180deg);
  }
  .dialogattch {
    width: 18px;
    height: 19px;
    background: #fff;
    border-color: #000000;
    border-radius: 0px 0 30px 0;
    border: none;
    position: relative;
    left: 0.5px;
    display: flex;
    border-right: 1px solid rgba(85, 178, 246, 1);
    .dialogattchinvert {
      width: 20px;
      height: 19px;
      background: #fff;
      position: absolute;
      left: 3px;
      top: 0px;
      z-index: -1;
      border: 1px solid rgba(85, 178, 246, 1);
      border-right: none;
      border-top: none;
      overflow: hidden;
    }
  }
}

/* 全局动画 */
.ani {
  position: relative;
  animation: renderfrist 1s linear;
}

.titlerender {
  animation: redertitle 0.5s linear 0.5s forwards;
}
.goodcontentrender {
  animation: rendergoodcontent 1s linear 1s forwards;
}

.bookcontentrender {
  animation: booktransform 2s ease-in-out 0s forwards;
}

.leftenterender {
  animation: leftender 1s linear 1s forwards;
}

.rightenterender {
  animation: rightender 1s linear 1s forwards;
}

@keyframes renderfrist {
  0% {
    top: 100px;
    opacity: 0;
  }
  100% {
    top: 0;
    opacity: 1;
  }
}

@keyframes topcontentredner {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes redertitle {
  0% {
    top: 100px;
    opacity: 0;
  }
  100% {
    top: 0;
    opacity: 1;
  }
}

@keyframes redertagimg {
  0% {
    transform: rotateY(0deg);
  }
  100% {
    transform: rotateY(360deg);
  }
}

@keyframes rendergoodcontent {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes booktransform {
  0% {
    transform: rotateY(0deg);
  }
  100% {
    transform: rotateY(360deg);
  }
}

@keyframes leftender {
  0% {
    left: -100px;
    opacity: 0;
  }
  100% {
    left: 0;
    opacity: 1;
  }
}

@keyframes rightender {
  0% {
    right: -100px;
    opacity: 0;
  }
  100% {
    right: 0;
    opacity: 1;
  }
}
</style>
