<template>
  <div class="vant-table">
    <table cellspacing="0" style="width: 100%; height: 300px" class="table">
      <tr class="tr">
        <th class="th" v-for="(item, index) in option.column" :key="index">
          {{ item.label }}
        </th>
      </tr>
      <tr v-for="(item, index) in tableData" :key="index" class="list-tr">
        <td v-for="(context, i) in option.column" :key="i">
          {{ item[context.tableDataprop] }}
        </td>
      </tr>
    </table>
  </div>
</template>
<script>
export default {
  name: "TableVant",
  props: {
    tableData: {
      type: Array,
      default: [],
    },
    option: {
      type: Object,
      default: {},
    },
  },
  created() {},
};
</script>

<style scoped>
::-webkit-scrollbar {
  width: 0px !important; 
}
.vant-table {
  height: 300px;
  overflow: auto;
  
}
.table {
  border-collapse: separate;
  border-spacing: 0;
  border-radius: 10px;
  border: solid 2px #dfdfdf;
  overflow: hidden;
  box-shadow: 0px 0px 40px 0px rgba(25, 115, 250, 0.15);
}

.tr {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  font-size: 12px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #ffffff;
}
.th {
  height: 10px;
  background-color: #103ff7;
  text-align: center;
  padding: 10px;
  font-size: 10px;
}
.list-tr {
  height: 1.074074rem;
  line-height: 1.074074rem;
  font-size: 8px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #000000;
}
.list-tr:nth-child(2n) {
  /*background-color: #33333b;*/
}
td {
  text-align: center;
  padding: 5px;
  font-size: 12px;
}
</style>
