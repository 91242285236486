<template>
  <div class="footer">
    <van-collapse v-model="activeNames" :border="false">
      <van-collapse-item title="快速导航" name="1">
        <div class="nav">
          <router-link  class="item" :to="{name:'Home'}">博轩公考首页</router-link>
          <router-link  class="citem" :to="{name:'teacher'}">师资信息</router-link>
          <router-link  class="citem" :to="{name:'about'}">关于我们</router-link>
          <router-link  class="item" :to="{name:'recruit'}">加入我们</router-link>
        </div>
      </van-collapse-item>
      <van-collapse-item title="联系我们" name="2">
        <div class="connect">
          <div class="item" style="margin-top: 0;">
            <img src="../../assets/logo/phone.png" alt="logo" />
            <p class="commonp">17798207841</p>
          </div>
          <div class="after">工作时间:9:00-24:00</div>
          <div class="item">
            <img src="../../assets/logo/connet.png" alt="logo" />
            <p class="commonp">1334720600@qq.com</p>
          </div>
          <div class="after">简历投递</div>
        </div>
      </van-collapse-item>
    </van-collapse>

    <div class="line"></div>

    <div class="ourinfo">
      <div class="com">
        <p class="commonp">博轩公考</p>
        <p class="commonp" style="margin: 0 4px">武汉博轩教育科技有限公司</p>
        <p class="commonp">版权所有</p>
      </div>
      <div class="num">
        <img
          class="img"
          src="https://oss.oxke.net/boxuanedu/footer/po.png"
          alt="logo"
        />
        <p class="commonp">鄂ICP备2023002056号-1</p>
      </div>
      <div class="com">
        <p class="commonp">本站由</p>
        <p class="commonp" style="margin: 0 4px">博轩数据中心</p>
        <p class="commonp">提供用户服务和技术支持</p>
      </div>
      <div class="com">
        <p class="commonp">Copyright</p>
        <p class="commonp" style="margin: 0 4px">© 2020-2023</p>
        <p class="commonp">All Rights</p>
      </div>
    </div>
  </div>
</template>

<script>
import { Collapse, CollapseItem } from "vant";
export default {
  components: {
    [Collapse.name]: Collapse,
    [CollapseItem.name]: CollapseItem,
  },
  data() {
    return {
      activeNames: ["1", "2"],
    };
  },
};
</script>

<style lang="less" scoped>
.footer {
  width: 100%;
  background: #1e2021;
  padding: 20px 0;
  position: relative;
  bottom: -20px;
  .nav {
    font-size: 14px;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: #ffffff;
    .item {
      margin-left: 16px;
      display: block;
    }
    .citem {
      display: block;
      margin: 11px 16px;
    }
  }

  .connect {
    font-size: 14px;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: #ffffff;
    .item {
      display: flex;
      margin-left: 16px;
      margin-top: 13px;
      img {
        width: 13px;
        object-fit: contain;
        margin-right: 7px;
      }
    }
    .after {
      font-size: 12px;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: #acacac;
      margin-left: 36px;
      margin-top: 5px;
    }
  }

  .ourinfo {
    padding: 18px;
    .com {
      display: flex;
      margin-bottom: 7px;
    }
    .num {
      margin-bottom: 7px;
      display: flex;
      align-items: baseline;
      .img {
        width: 10px;
        height: 10px;
        margin-right: 4px;
      }
    }
  }
}

.commonp {
  font-size: 12px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #acacac;
}

.line {
  width: 345px;
  height: 0px;
  opacity: 1;
  border: 0.2px solid #c7c6c6;
  margin-left: 16px;
}
::v-deep .van-cell {
  background: #1e2021 !important;
  color: #fff;
  font-size: 18px;
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 500;
}

::v-deep .van-collapse-item__content {
  padding: 3.2vw 4.266667vw;
  font-size: 3.733333vw;
  line-height: 1.5;
  background-color: #1e2021 !important;
  color: #fff;
}
::v-deep .van-collapse-item__title--expanded::after {
  display: none;
}
</style>
