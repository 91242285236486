<template>
  <div class="lessons">
    <div class="lessonhead">
      <img
        class="smallogo"
        src="@/assets/img/logo/logo.png"
        alt="smallogo"
        @click="tohome"
      />
      <van-search
        class="vs"
        v-model="value"
        placeholder="找好课，寻名师，学知识"
      />
    </div>

    <div class="tagcontent">
      <div
        class="item"
        v-for="(item, index) in tagsbanner1"
        :key="index"
        @click="tolesson(item.title)"
      >
        <img class="tagimg" :src="item.img" alt="tagimg" />
        <p>{{ item.title }}</p>
      </div>
    </div>

    <div class="tagcontent">
      <div
        class="item"
        v-for="(item, index) in tagsbanner2"
        :key="index"
        @click="tolesson(item.title)"
      >
        <img class="tagimg" :src="item.img" alt="tagimg" />
        <p>{{ item.title }}</p>
      </div>
    </div>

    <div class="tagtip">
      在 “{{ tag }}”分类下，共有{{ thelesson.length }}门课程
    </div>

    <div class="lessoncards" v-if="thelesson.length">
      <div
        v-for="(item, index) in thelesson"
        :key="index"
        @click="tolessoninfo(item.paycode, 'all')"
      >
        <hotlessoncard :data="item"></hotlessoncard>
      </div>
    </div>
    <div v-else>
      <p>更多课程内容正在准备中...</p>
    </div>

    <myfooter></myfooter>
  </div>
</template>

<script>
import { Search, Notify } from "vant";
import { bxlessons } from "../../tempdata/lesson/lessons";
import hotlessoncard from "../components/hotlessoncard.vue";
import myfooter from "../headfooter/foot.vue";
export default {
  components: {
    [Search.name]: Search,
    [Notify.name]: Notify,
    hotlessoncard,
    myfooter,
  },
  data() {
    return {
      tagsbanner1: [
        {
          img: require("@/assets/img/home/tagsbanner/组 1845.png"),
          title: "全部",
        },
        {
          img: require("@/assets/img/home/tagsbanner/组 1846.png"),
          title: "热门课程",
        },
        {
          img: require("@/assets/img/home/tagsbanner/组 1847.png"),
          title: "优选课程",
        },

        {
          img: require("@/assets/img/home/tagsbanner/选调.png"),
          title: "选调生",
        },
        {
          img: require("@/assets/img/home/tagsbanner/组 1849.png"),
          title: "事业编",
        },
      ],
      tagsbanner2: [
        {
          img: require("@/assets/img/home/tagsbanner/组 1998.png"),
          title: "医疗",
        },

        {
          img: require("@/assets/img/home/tagsbanner/组 1999.png"),
          title: "三支一扶",
        },
        {
          img: require("@/assets/img/home/tagsbanner/金融.png"),
          title: "银行金融",
        },

        {
          img: require("@/assets/img/home/tagsbanner/组 1997.png"),
          title: "教师编",
        },
        {
          img: require("@/assets/img/home/tagsbanner/组 1848.png"),
          title: "公务员",
        },
      ],

      /* 所有课程 */
      bxlessons,

      thelesson: [],
      tag: "",
    };
  },
  created() {
    this.setleeson();
  },
  methods: {
    /* 进入课程汇总页(重写) */
    tolesson(tagname) {
      console.log(tagname);
      const querytagname = this.$route.query.tagname;
      if (tagname == querytagname) {
        return;
      }

      this.$router.push({
        name: this.$route.name,
        query: { tagname },
      });

      this.setleeson();
    },
    /* 进入课程详情页 */
    tolessoninfo(code, type) {
      // 针对新引流课 进入新的课程样式
      if (code == "EDDn" || code == "M0xg") {
        this.$router.push({ name: "pubcourse" });
        return;
      }
      this.$router.push({ name: "lessoninfo", query: { code } });
    },
    /* 给不同的tag设置不同的课程数据 */
    setleeson() {
      this.thelesson = [];
      const tag = this.$route.query.tagname;
      this.tag = tag;
      if (tag == "全部") {
        this.thelesson = this.bxlessons;
        return;
      }
      for (const item of bxlessons) {
        for (const tagname of item.tags) {
          if (tag == tagname) {
            this.thelesson.push(item);
          }
        }
      }
      if (!this.thelesson.length) {
        Notify({
          type: "primary",
          message: `${tag}分类的课程正在准备中...敬请期待`,
        });
        this.thelesson = this.bxlessons;
        this.tag = "全部";
      }
    },
    /**/
    tohome() {
      this.$router.push({ name: "Home" });
    },
  },
};
</script>

<style lang="less" scoped>
.lessons {
  width: 100vw;
  .lessonhead {
    display: flex;
    height: 60px;
    align-items: center;
    .smallogo {
      width: 30px;
      height: 30px;
      margin-left: 10px;
    }
    .vs {
      width: 90%;
    }
  }
  .tagcontent {
    height: 80px;
    padding: 5px;
    display: flex;
    justify-content: space-between;
    text-align: center;
    font-size: 12px;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: #2d2d2d;
    .item {
      .tagimg {
        width: 42px;
        height: 42px;
      }
    }
  }

  .tagtip {
    margin-top: 12px;
    width: 100%;
    font-size: 12px;
    text-align: center;
    background: #efeff4;
    padding: 10px;
  }

  .lessoncards {
    display: flex;
    margin-top: 16px;
    flex-wrap: wrap;
    margin-left: 12px;
  }
  .lessoncards:nth-child(even) {
    margin-right: 0px;
  }
}

/* 全局css样式 */
.line {
  width: 100vw;
  height: 6px;
  background: #e3dfdf;
  margin-top: 12px;
}
</style>
