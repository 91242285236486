<template>
  <div class="hotlessoncard">
    <img
      class="hotlessonimg"
      :src="data.img"
      alt="hotlesson"
    />
    <p class="title">{{data.lessontitle}}</p>
    <div class="itemtags">
      <div class="tag" v-for="(item,index) in data.tags" :key="index">
        <p>{{item}}</p>
      </div>

    </div>
    <div class="price">
      <div class="icon">
        ￥
        <p class="num">{{data.price}}</p>
      </div>
      <span>{{data.people}}人报名</span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    data: Object,
    required: true,
  },
};
</script>

<style lang="less" scoped>
.hotlessoncard {
  width: 170px;
  height: 204px;
  background: #ffffff;
  box-shadow: 0px 0px 6px 1px rgba(39, 105, 255, 0.1);
  border-radius: 10px 10px 10px 10px;
  margin-bottom: 22px;
  margin-right: 10px;
  .hotlessonimg {
    width: 171px;
    height: 106px;
    border-radius: 10px 10px 0px 0px;
  }
  .title {
    font-size: 14px;
    font-family: PingFang SC-Bold, PingFang SC;
    font-weight: bold;
    color: #141414;
    line-height: 25px;
    margin-left: 6px;
    margin-bottom: 20px;
  }
  .itemtags {
    display: flex;
    margin-left: 6px;
    margin-bottom: 10px;
    .tag {
      padding: 2px 4px;
      height: 18px;
      background: #ececec;
      border-radius: 3px 3px 3px 3px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 6px;
      p {
        font-size: 10px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #1b60ff;
      }
    }
  }
  .price {
    display: flex;
    padding: 0 6px;
    align-items: flex-end;
    justify-content: space-between;
    .icon {
      display: flex;
      align-items: flex-end;
      font-size: 12px;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: #1b60ff;
      .num {
        font-size: 18px;
        font-family: PingFang SC-Bold, PingFang SC;
        font-weight: bold;
        color: #1b60ff;
        line-height: 17px;
      }
    }
    span {
      font-size: 10px;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: #b9b9b9;
    }
  }
}
</style>
